<template>
<nav class="p-0">
  <!--
   <router-link :to="{ name: 'first mission' }" class="text-decoration-none font2 fw-semibold">İlk Görev</router-link>
   -->
</nav>
  <router-view/>
<div id="app">

</div>
</template>
<style scoped lang="scss">
#app {
  font-family: 'Poppins', sans-serif !important;
}
*{
   font-family: 'Poppins', sans-serif !important;
}
/* Hide scrollbar in Chrome, Safari and Opera */
 #app::-webkit-scrollbar {
   display:none;
 }

 /* Hide scrollbar for IE, Edge and Firefox */
 #app {
  -ms-overflow-style: none; /* IE and EDGE */;
   scrollbar-width: none; /* Firefox */
 }
</style>
<script>
import { defineComponent } from 'vue'
import { useAuthStore } from './store/auth'

export default defineComponent({
  name: 'App',
  setup () {
    const authStore = useAuthStore()
    return {
      authStore
    }
  },
  components: {

  }
})
</script>
